.modal.VouchModal {
  &--newMember {
    &.card {
      border: 0;
    }

    .closeWrapper {
      position: absolute;
      top: 24px;
      right: 24px;

      & > svg > path {
        fill: #1e40af;
      }

      & > svg > path:nth-of-type(2) {
        fill: white;
      }

      &:hover > svg > path:nth-of-type(1) {
        stroke: none;
      }

      &:active > svg > path {
        fill: #1e40af !important;
      }

      &:active > svg > path:nth-of-type(2) {
        fill: white !important;
      }
    }
  }
}
