@import "../variables.scss";

.Register {
  &__heading {
    margin-top: 48px;

    @media screen and (max-width: $mobileWidth) {
      margin-top: 0;
    }
  }
  &__col {
    @media screen and (max-width: $mobileWidth) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.MiniProgressListContainer {
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  z-index: 9999;
}

@media screen and (max-width: 600px) {
  .MiniProgressListContainer {
    display: flex;
  }
}
