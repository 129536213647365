.Header__Box--loading {
  .nav-item {
    visibility: hidden;
  }
}

.UnionWallet.button.UnionWallet.button {
  height: 32px;
  min-height: 0;
  padding: 8px;

  .text {
    font-size: 14px !important;
  }
}
