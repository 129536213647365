@import "@rainbow-me/rainbowkit/styles.css";
@import "@unioncredit/ui/lib";
@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  background-color: #fff;

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  color: unset;
  text-decoration: unset;
}

*[contenteditable="true"] {
  outline: none;
  min-width: 100%;
}

.empty-state {
  a {
    color: #3b82f6;
  }
}

.backButton ~ .modal__title {
  width: 100%;
  position: absolute;
  left: 0;
}

.label--clickable,
.badge--clickable {
  cursor: pointer;
}

.TableContainer {
  width: 100%;
  overflow: auto;
}

.layout-header {
  padding: 16px 0;
  margin-bottom: 24px;

  @media screen and (max-width: $mobileWidth) {
    margin-bottom: 0;
  }
}

.context-menu {
  width: 190px !important;
}

.v2-alert {
  .alert {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .button {
    padding: 4px 8px !important;
    min-height: 0;
  }
}

.networkSwitcher--optimism {
  background: #fee2e2 !important;

  svg path {
    fill: #ef4444;
  }

  &:hover {
    border-color: #fca5a5;
  }

  color: #ef4444;
}
